import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeIn, fadeInOut } from 'src/app/animations/animations';

@Component({
  selector: 'hh-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [fadeInOut, fadeIn]
})
export class ModalComponent implements OnInit {
  @Output() actionClicked = new EventEmitter();
  @Output() seconaryActionClicked = new EventEmitter();
  @Output() headerActionClicked = new EventEmitter();
  @Output() headerSecondActionClicked = new EventEmitter();
  @Output() modalClosed = new EventEmitter();
  @Input() showHeader: boolean | null = true;
  @Input() showFooter: boolean | null = true;
  @Input() showActionButton: boolean | null = true;
  @Input() showSecondaryActionButton: boolean | null = false;
  @Input() showHeaderActionButton: boolean | null = false;
  @Input() showHeaderSecondActionButton: boolean | null = false;
  @Input() title: string | null = null;
  @Input() actionText: string = '';
  @Input() actionDisabled: boolean = false;
  @Input() secondaryActionText: string = '';
  @Input() secondaryActionDisabled: boolean = false;
  @Input() headerActionText: string = '';
  @Input() headerSecondActionText: string = '';
  @Input() headerActionDisabled: boolean = false;
  @Input() headerSecondActionDisabled: boolean = false;
  @Input() loading: boolean | null = false;
  @Input() modalOpen: boolean | null = false;
  @Input() modalSize: string = '600px';
  @Input() isClosingWarning: boolean | null = false;
  @Output() beforeClose = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  // Added warning flag on closure of the modal. so added beforeClose event if we have isClosingWarning is true
  closeModal() {
    if(!this.isClosingWarning){
      this.modalOpen = false;
      this.modalClosed.emit();
    }else{
      this.beforeClose.emit();
    }
  }
}
