<div *ngIf="modalOpen" [@fadeInOut] class="modal">
  <div class="modal--container" [ngStyle]="{ width: modalSize }">
    <div class="modal--content">
      <div *ngIf="showHeader" class="modal--header">
        <div>{{ title }}</div>
        <div style="display: flex; align-items: center;">
          <button
              *ngIf="headerActionText && showHeaderActionButton"
              [@fadeIn]
              (click)="headerActionClicked.emit()"
              [ngClass]="{ disabled: headerActionDisabled }"
              [disabled]="headerActionDisabled"
              class="button empty small"
              style="margin: 0 10px 0 0;"
            >
              {{ headerActionText }}
            </button>
            <button
              *ngIf="headerSecondActionText && showHeaderSecondActionButton"
              [@fadeIn]
              (click)="headerSecondActionClicked.emit()"
              [ngClass]="{ disabled: headerSecondActionDisabled }"
              [disabled]="headerSecondActionDisabled"
              class="button empty small"
              style="margin: 0 10px 0 0;"
            >
              {{ headerSecondActionText }}
            </button>
            <div *ngIf="loading" [@fadeIn] class="loader small"></div>
        </div>
      </div>
      <div class="modal--body">
        <ng-content></ng-content>
      </div>
      <div *ngIf="showFooter" class="modal--footer">
        <button (click)="closeModal()" class="button empty small">Cancel</button>
        <div class="modal--action_buttons">
          <button
            *ngIf="secondaryActionText && showSecondaryActionButton"
            [@fadeInOut]
            (click)="seconaryActionClicked.emit()"
            [ngClass]="{ disabled: secondaryActionDisabled }"
            [disabled]="secondaryActionDisabled"
            class="button neutral small"
          >
            {{ secondaryActionText }}
          </button>
          <button
            *ngIf="showActionButton"
            [@fadeInOut]
            (click)="actionClicked.emit()"
            [ngClass]="{ disabled: actionDisabled }"
            [disabled]="actionDisabled"
            class="button primary small"
          >
            {{ actionText }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div [@fadeInOut] (click)="closeModal()" class="backdrop"></div>
</div>